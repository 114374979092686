import React, {useEffect, useCallback, useState} from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputFile from "../../../Components/Dropzone";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import DocumentSchema from "../../../../../services/Schemas/Front/Agreement/DocumentSchema";
import { create} from "../../../../../services/Apis/Front/AgreementDocuments.service";
import FileInput from "../../../Components/FileInput";

function AddDocumentModal(props: any) {

    const [files, setFiles] = useState<FileList | null>(null);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFiles(e.target.files);
        }
    };

    const { control, register, handleSubmit, formState:{ isDirty, isValid, errors }, setValue, watch } = useForm({
        mode: "onChange",
        resolver: yupResolver(DocumentSchema)
    });

    const handleDocument = async (data: any) => {
        const formData = new FormData();

        if (files !== null) {
            // @ts-ignore
            [...files].forEach((file) => {
                formData.append('files[]', file);
            });
        }

        try {
            const response = await create(props.agreementid, formData);
            props.callback();
            props.onHide();
        } catch (err) {

        }
    }

    return (
        <>
            <Modal
                className={`add-document-modal`}
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <form onSubmit={handleSubmit(handleDocument)}>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Opret Dokumenter
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className={`row row-flex row-flex-wrap`}>
                            <Col xl={12} sm={12}>
                                {/*<InputFile control={control} name="files" />*/}
                                <FileInput handleFileChange={handleFileChange} />
                            </Col>
                            <div className="invalid-feedback-error">{errors?.files?.message?.toString()}</div>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type={`submit`}>Add Files</Button>
                        <Button onClick={props.onHide}>Close</Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default AddDocumentModal;