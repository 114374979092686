import axios from "../../../lib/axios";

export async function getAll(id, page, perPage) {
    const response = await axios.get('/agreements/'+id+'/documents?page='+page);
    return response;
}

export async function get(id) {
    const response = await axios.get('/agreements/'+id);
    return response.data;
}

export async function create(id, data) {
    const response = await axios.post('/agreements/'+id+'/documents', data);
    return response.data;
}