import React from 'react';
import {useState} from "react";

function FileInput(props:any) {

    return (
        <>
            <div className="input-group">
                <input id="file" type="file" className={`form-control`} multiple onChange={props.handleFileChange} />
            </div>
        </>
    );
};

export default FileInput;