import React, { useState, useEffect, useCallback } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FrontLayout from "../../../../components/FrontLayout/Layout";
import Loading from "../../../../components/Loading";
import {get as getAgreement } from "../../../../services/Apis/Front/Agreements.service";
import IAgreement from "../../../../interfaces/IAgreement";
import {Link, useParams} from "react-router-dom";
import AddDocumentModal from "./components/AddDocumentModal";
import {getAll} from "../../../../services/Apis/Front/AgreementDocuments.service";
import DataTable from "react-data-table-component";

function AccountAgreementDocuments() {

    const [isLoading, setIsLoading] = useState(true);
    const [agreement, setAgreement] = useState<IAgreement>();
    const [modalShow, setModalShow] = useState(false);
    let { agreementId } = useParams();

    const [documents, setDocuments] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const columns = [
        {
            name: 'Navn',
            selector: (row: any) => row.file_file_name,
            width: '80%'
        },
        {
            name: 'Andet',
            selector: (row: any) => <>
                <button type={"button"} onClick={() => ''} className={`small-btn delete`} title={'Slet'}></button>
            </>,
            width: '20%'
        },
    ];

    const getDocuments = async (page: number) => {
        setIsLoading(true);

        const response = await getAll(agreementId, page, perPage);

        setDocuments(response.data.data);
        setTotalRows(response.data.pagination.total);
        setIsLoading(false);
    };

    useEffect(() => {
        (async () => {
            setIsLoading(false);
            const response = await getAgreement(agreementId);
            await getDocuments(1);
            setAgreement(response);
        })();
    }, []);

  return (
      <FrontLayout>
          <>
              {isLoading &&
                  <Loading/>
              }

              {!isLoading &&
                  <>
                      <Row className={`row-flex row-flex-wrap`}>
                          <Col xl={12}>
                              <div className="arv-top white-box nopad">
                                  <div className="h-box-title">Dokumenter</div>
                                  <div className="arv-top-btn"><button type={`button`} onClick={() => setModalShow(true)} className={`blue-btn`}>+ Opret dokumenter</button></div>
                              </div>
                          </Col>
                      </Row>
                      <Row>
                          <Col xl={12}>
                              <div className="white-box arv-form-box">
                                  <DataTable
                                      title=""
                                      columns={columns}
                                      data={documents}
                                      progressPending={isLoading}
                                      pagination
                                      paginationServer
                                      paginationTotalRows={totalRows}
                                      paginationComponentOptions={{noRowsPerPage: true}}
                                  />
                              </div>
                          </Col>
                      </Row>

                      <AddDocumentModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                          agreementid={agreementId}
                          callback={getDocuments}
                      />
                  </>
              }
          </>
      </FrontLayout>
  );
}

export default AccountAgreementDocuments;
